// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-djinni-js": () => import("./../../../src/pages/djinni.js" /* webpackChunkName: "component---src-pages-djinni-js" */),
  "component---src-pages-fluffymap-js": () => import("./../../../src/pages/fluffymap.js" /* webpackChunkName: "component---src-pages-fluffymap-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wanderolla-js": () => import("./../../../src/pages/wanderolla.js" /* webpackChunkName: "component---src-pages-wanderolla-js" */),
  "component---src-pages-wooico-js": () => import("./../../../src/pages/wooico.js" /* webpackChunkName: "component---src-pages-wooico-js" */)
}

